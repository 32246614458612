import React, { useState, useEffect, useRef } from 'react'
import StorageApi from '../APIConfig/MainApi';
import SelectSearch from 'react-select';
import Link from 'next/link';
import Image from 'next/image';
import { getSession } from "next-auth/react"
import { Select, Tooltip, message } from 'antd';


/*
  ================
  = Static Form  =
  ================
  Berisikan data, dimana data tersebut diambil dari API yang terhubung ke backend

*/

export function SelectMultiCctv(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polres_id) => {
    const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?is_active=1&` + polres_id));
    // const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?${props.Checkpoint ? 'wilayah_cctv=checkpoint' : 'wilayah_cctv=perbatasan'}&polres_id=` + polres_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map((item) =>
       props.valLabel ?
        daftar.push({ label: item.nama_cctv, value: item.nama_cctv }) :
        daftar.push({ label: item.nama_cctv, value: item.rowid }))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      // console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        className='w-full h-9'
        // style={{ width: '100%'}}
        placeholder={props.placeholder}
        value={props.value}
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        onChange={props.onChange}
        allowClear="true"
        maxTagCount= 'responsive'
        disabled={props.disabled}
        maxTagPlaceholder={(comittedValues) => (
          <Tooltip title={CapitalText(comittedValues.map(({ label }) => label).join(', '))?.replaceAll('Ctv', 'CTV')}>
            <span>+ {comittedValues.length} Data terpilih</span>  
          </Tooltip>
        )} 
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectNrp(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.user);
  }, [props.user])

  const ListCategory = async (user) => {
    const sesi = await getSession()
    let arr = []
    if (sesi.polda_id) { arr = [...arr, `polda_id=${sesi.polda_id}`] }
    if (sesi.polres_id) { arr = [...arr, `polres_id=${sesi.polres_id}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi(`${user ? `sm_hrm/user_master${sesi.kesatuan == "MABES" ? '?' : `${param}&`}actived=1` : `sm_hrm/user_master${param}`}`));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: `${(item.nrp == undefined) ? `${item.id_unique} (${item.kode_jenis_user})` : `${item.nrp} (${item.kode_jenis_user})`}`, value: item.kode_user_master + "|" + item.nama })
      ))
      props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPetugas(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.user);
  }, [props.user])

  const ListCategory = async (user) => {
    const sesi = await getSession()
    let arr = []
    if (sesi.polda_id) { arr = [...arr, `polda_id=${sesi.polda_id}`] }
    if (sesi.polres_id) { arr = [...arr, `polres_id=${sesi.polres_id}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi(`sm_hrm/user_master?unit=SM_SMART_WORKER`));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: `${item.nama}`, value: item.id_unique + "|" + item.nama })
      ))
      props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}


export function SelectUnitInstansi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_master_data/unit_instansi?sotk_id=${param}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kelompok, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisUser(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/jenis_user'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: `User ${item.jenis_user == "External" ? "Stakeholder" : item.jenis_user}`, value: item.kode_jenis_user })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectInstansiPetugas(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/petugas_group_by_sotk?activity=1'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.sotk, value: item.sotk_id })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectModul(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const sessi = await getSession()
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/modul'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        item.kode_modul != "DEV_OPS" || sessi.kode_unit == "DEV_OPS" ? 
        daftar.push({ label: item.modul, value: item.kode_modul })
         : ""
      ))
      props.clearSelect ? daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' }) : ''
      setDetail(daftar)
    }).catch(e => {
      // console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisPendidikan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/jenis_pendidikan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_pendidikan, value: item.rowid })
      ))
      props.clearSelect ? daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' }) : ''
      setDetail(daftar)
    }).catch(e => {
      // console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}


export function SelectKelompokSasaran(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kelompok_sasaran'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kelompok_sasaran, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      // console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectMedia(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/media'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.media, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      // console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectSubModul(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const sesi = await getSession()
    const a = Promise.resolve(StorageApi.getRelasi(`sm_hrm/kode_sub_unit?kode_unit=${param}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item => (
        sesi.kode_unit != "DEV_OPS" ?
        item.status == "1" ? (
          daftar.push({ label: item.sub_unit, value: item.kode_sub_unit })
        ) : ""
        :(
          daftar.push({ label: item.sub_unit, value: item.kode_sub_unit })
        )
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisWisata(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_wisata'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_wisata, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisService(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_service'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_service, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectSmartOps(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [props.param])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_master_data/smart_ops`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.smart_ops, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisNews(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_berita'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_berita, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisEvent(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_event'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_event, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectJenisGalery(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_galery'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_galery, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}



export function SelectPage(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_hrm/page`));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.page, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectPageGroup(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_hrm/page_group?level=${props.param}`));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: `${item.page} - (${item.kode_unit}) - (${item.kode_sub_unit})`, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectSertifikasi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/jenis_sertifikasi'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_sertifikasi, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectPelatihan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/jenis_pelatihan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_pelatihan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectNoKontrak(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/kontrak_kerja'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.no_kontrak, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPendidikan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (param) => {
    var url = ""
    if (param == "dikbang") {
      url = "sm_hrm/sub_jenis_pendidikan?jenis_pendidikan_id=3"
    } else if (param == "dikbang spes") {
      url = "sm_hrm/sub_jenis_pendidikan?jenis_pendidikan_id=2"
    } else {
      url = "sm_hrm/sub_jenis_pendidikan?jenis_pendidikan_id=1"
    }
    const a = Promise.resolve(StorageApi.getRelasi(url));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.nama_sekolah, value: item.rowid })
      ))
      props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full capitalize"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectStatusKepegawaian(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/status_kepegawaian'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.status_pegawai, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectInstansiExternal(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/instansi_external'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.instansi_external, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriKml(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const sessi = await getSession()
    let arr = []
    if (sessi.polda_id) { arr = [...arr, `polda_id=${sessi.polda_id}`] }
    if (sessi.polres_id) { arr = [...arr, `polres_id=${sessi.polres_id}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kml_kategori' + param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}
export function SelectJenisPelatihan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('jenis_pelatihan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_pelatihan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectCctv(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polres_id) => {
     const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?is_active=1&polres_id=` + polres_id));
    // const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?${props.Checkpoint ? 'wilayah_cctv=checkpoint' : 'wilayah_cctv=perbatasan'}&polres_id=` + polres_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
         props.valLabel ?
        daftar.push({ label: item.nama_cctv, value: item.nama_cctv}) :
        daftar.push({ label: item.nama_cctv, value: item.rowid}))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={(props.disabled) ? true : false}
      />
    </>
  )
}

export function SelectCctvPrasjal(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polres_id) => {
    const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?is_active=1&` + polres_id));
    // const a = Promise.resolve(StorageApi.getRelasi(`patroli_cctv/cctv?${props.Checkpoint ? 'wilayah_cctv=checkpoint' : 'wilayah_cctv=perbatasan'}&polres_id=` + polres_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
         props.valLabel ?
        daftar.push({ label: item.nama_cctv, value: item.rowid, channel_id: item.channel_id, lat: item.lat, lng: item.lng, nama_jalan_s: item.nama_jalan_s }) :
        daftar.push({ label: item.nama_cctv, value: item.rowid, channel_id: item.channel_id, lat: item.lat, lng: item.lng, nama_jalan_s: item.nama_jalan_s }))
      
      daftar.sort((a, b) => {
        if(a.status_cctv && b.status_cctv) { 
            return a.status_cctv.localeCompare(b.status_cctv);
        }
        return 0;
      });
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={(props.disabled) ? true : false}
      />
    </>
  )
}

export function SelectPolda(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.polda_id) { arr = [...arr, `polda_id=${sesi.polda_id}`] }
    let param=''
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi('polda'+param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
         props.daerah ?  daftar.push({ label: item.polda.replaceAll('Polda', ''), value: item.polda_id }) : item.is_active && daftar.push({ label: item.polda, value: item.polda_id })
      ))
      // props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectStatusPesan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/status_pesan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.status_pesan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectProgramKerja(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_master_data/program_kerja?jenis_rsp=${param}`));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.program_kerja, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategori(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full "
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJudulNews(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_portal/news'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.judul_news, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full "
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectStatusJalan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/status_jalan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.status_jalan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisKerawanan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kerawanan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kerawanan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectFungsiJalan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/fungsi_jalan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.fungsi_jalan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKerusakanObject(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kerusakan_object'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kerusakan_object, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKebutuhanPeralatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kebutuhan_peralatan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kebutuhan_peralatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriPelapor(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_pelapor'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_pelapor, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRenGiatJenis(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_kegiatan?jenis_form=Rengiat'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRenOpsJenis(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_kegiatan?jenis_form=RenOps Pol'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        props.disabletitikPosko ? 
        item.jenis_kegiatan != "TITIK POSKO" ? 
        daftar.push({ label: item.jenis_kegiatan, value: item.rowid }) : ""
        :daftar.push({ label: item.jenis_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRenMasJenis(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_kegiatan?jenis_form=Rengiat Masyarakat'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPenyebabKemacetan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/penyebab_kemacetan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.penyebab_kemacetan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPanjangAntrian(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/panjang_antrian'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.panjang_antrian, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPerijinanJenis(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_kegiatan?jenis_form=Perijinan Pekerjaan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisKendaraan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_kendaraan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_kendaraan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectFasilitas(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/fasilitas'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        props.param == "pmy" ? (
          item.kategori_fasilitas == "PUBLIC SECTOR" ? 
          daftar.push({ label: item.fasilitas, value: item.rowid }) : ""
        ) : (
          daftar.push({ label: item.fasilitas, value: item.rowid })
        )
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisPengemudi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_pengemudi'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_pengemudi, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKejadianOpsdal(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kejadian_opsdal'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kejadian_opsdal, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectObjectKawal(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/object_kawal'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.object_kawal, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectCaraBertindak(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/cara_bertindak'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        props.string ? daftar.push({ label: item.cara_bertindak, value: item.cara_bertindak }) : daftar.push({ label: item.cara_bertindak, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRenGiatKategori(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_kegiatan?jenis_form=Rengiat'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRenOpsKategori(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_kegiatan?jenis_form=RenOps Pol'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_kegiatan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectDasarKegiatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/dasar_kegiatan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.dasar_giat, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectSasaranKegiatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/sasaran_kegiatan?jenis_form='+props.param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.sasaran_giat, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectMultiSasaranKegiatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/sasaran_kegiatan?jenis_form='+props.param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.sasaran_giat, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <Select
        mode="multiple"
        className='w-full h-9'
        placeholder={props.placeholder}
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
         value={props.value ? props.value : null}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
      {/* <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      /> */}
    </>
  )
}

export function SelectCustomAntd({Name='',className='',Status='',disabled=false,value,placeholder='Select Option',children,showSearch=false,allowClear=false,onChange=false,multiple=false}) {
  return(
     <Select
        name={Name}
        mode={multiple ? 'multiple' : ''}
        value={value ? value : null}
        className={`w-full ${className}`}
        placeholder={placeholder}
        allowClear = {allowClear}
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        showSearch ={showSearch}
        disabled={disabled}
        onChange={onChange}
        options={
          children
        }
        maxTagCount= 'responsive'
        maxTagPlaceholder={multiple ? (comittedValues) => (
          <Tooltip title={CapitalText(comittedValues.map(({ label }) => label).join(', '))}>
            <span>+ {comittedValues.length} Data terpilih</span>  
          </Tooltip>
        ) : ''} 
        status={Status}
      />
  )
}

export function SelectSituasiLalin(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/situasi_lalin'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.situasi_lalin, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPotensiGangguan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/situasi_lalin'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.situasi_lalin, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKejadianTermonitor(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kejadian_termonitor'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kejadian_termonitor, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKondisiCuaca(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kondisi_cuaca'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kondisi_cuaca, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisProject(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_project'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_project, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriAplikasi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_aplikasi'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_aplikasi, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectStatusDevice(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/status_device'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.status_device, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriDevice(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_device'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_device, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisLayanan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_layanan'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_layanan, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectSubKategori(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (kategori_id) => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/sub_kategori?kategori_id=' + kategori_id));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.sub_kategori, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full "
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}



export function SelectSaluranInformasi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/saluran_informasi'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.saluran, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectPolres(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polda_id) => {
    const a = Promise.resolve(StorageApi.getRelasi('polres?polda_id=' + polda_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        props.wilayah ? daftar.push({ label: item.polres.replaceAll('POLRESTABES','').replaceAll('POLRESTA','').replaceAll('POLRES',''), value: item.polres_id }) : daftar.push({ label: item.polres, value: item.polres_id })
        )
      // props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={(props.disabled) ? true : false}
      />
    </>
  )
}

export function SelectProvinsi(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('provinsi'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.prov_name, value: item.prov_id })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectRoute(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/manage_route'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.manage_route, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKota(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (prov_id) => {
    const a = Promise.resolve(StorageApi.getRelasi(`${props.showall ? 'kota' : 'kota?prov_id=' + prov_id}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.city_name, value: item.city_id }))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}


export function SelectKecamatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (city_id) => {
    const a = Promise.resolve(StorageApi.getRelasi(`sm_master_data/kecamatan?city_id=${city_id}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.dis_name, value: item.dis_id }))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        isDisabled={props.disabled ? true : false}
        required
      />
    </>
  )
}

export function SelectUnit(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/unit'));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.nama, value: item.kode_unit })
      )
      props.clearSelect ? daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' }) : ''

      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
      />
    </>
  )
}

export function SelectMultiPrasjal(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/prasjal'));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.prasjal, value: item.kode_prasjal })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        className='w-full h-9'
        // style={{ width: '100%'}}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiPolda(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('polda'));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.prasjal, value: item.kode_prasjal })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiPolres(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polda_id) => {
    const a = Promise.resolve(StorageApi.getRelasi('polres?polda_id=' + polda_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.polres, value: item.polres_id }))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiPotensiGangguan(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kejadian_opsdal'));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.kejadian_opsdal, value: item.kode_kejadian_opsdal })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiSasaranOperasi(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/sasaran_kegiatan?jenis_form='+props.param));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.sasaran_giat, value: item.rowid })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiLayananDarurat(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/layanan_darurat'));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.layanan_darurat, value: item.kode_layanan_darurat })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        className='w-full h-9'
        // style={{ width: '100%'}}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export function SelectMultiSotk(props) {
  const [list, setDetail] = useState([]);
  const { Option } = Select;

  useEffect(e => {
    ListCategory(props.param, props.golongan);
  }, [])

  const ListCategory = async (param, golongan) => {
    const a = Promise.resolve(StorageApi.getRelasi(`${golongan == "KEDINASAN" ? `sotk?golongan=${golongan}` : `sotk?golongan=${golongan}`}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.sotk, value: item.rowid })
      )
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <Select
        mode="multiple"
        className='w-full h-9'
        // style={{ width: '100%'}}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        allowClear="true"
        disabled={props.disabled}
      >
        {list.map(item => {
          return (
            <Option value={item.value} label={item.label} key={item.value}>
              {item.label}
            </Option>
          )
        })}
      </Select>
    </>
  )
}



export function SelectPangkat(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.sub_unit) { arr = [...arr, `kategori_pangkat=${sesi.sub_unit}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi('pangkat' + param)); a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.pang_nam, value: item.rowid }))
      props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
      />
    </>
  )
}
export function SelectJabatan(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const a = Promise.resolve(StorageApi.getData(`jabatan?sotk_id=${param}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.jabatan, value: item.rowid }))
      props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
      />
    </>
  )
}

export function SelectFungsiKepolisian(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/fungsi_kepolisian?kategori=' + param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.fungsi_kepolisian, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectSubFungsiKepolisian(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props.param])

  const ListCategory = async (param) => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_hrm/sub_fungsi_kepolisian?fungsi_kepolisian_id=' + param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.sub_fungsi_kepolisian, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value == props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectSotk(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param, props.golongan);
  }, [props?.param])

  const ListCategory = async (param, golongan) => {
    const a = Promise.resolve(StorageApi.getRelasi(`${golongan == "KEDINASAN" ? `sotk?sub_fungsi_kepolisian_id=${param}&golongan=${golongan}` : `sotk?golongan=${golongan}`}`));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        daftar.push({ label: item.sotk, value: item.rowid }))
      props.clearSelect ? daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' }) : ''
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
      />
    </>
  )
}

export function SelectJenisGiat(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/fasilitas'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.fasilitas, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriBencana(props){
  return (
    <>
     <SelectCustom onChange={props.onChange} value={props.value} placeholder="Pilih kategori bencana">
        {
          [
            { label: 'BENCANA ALAM', value: 'BENCANA ALAM' },
            { label: 'BENCANA NON ALAM', value: 'BENCANA NON ALAM' },
            { label: 'BENCANA SOSIAL', value: 'BENCANA SOSIAL' },
          ]
        }
      </SelectCustom>
    </>
  )
}

export function SelectJenisBencana(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory();
  }, [])

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_bencana?kategori_bencana='+props.param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_bencana, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={'Pilih Jenis Bencana'}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectKategoriBarang(props) {
  const [list, setDetail] = useState([]);

  useEffect(() => {
    ListCategory();
  }, []);

  const ListCategory = async () => {
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/kategori_barang_hilang'));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.kategori_barang, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}

export function SelectJenisBarang(props) {
  const [list, setDetail] = useState([]);

  useEffect(() => {
    ListCategory();
  }, [props.kategori_barang]);

  const ListCategory = async () => {
    let arr = []
    if (props.kategori_barang) { arr = [...arr, `kategori_barang_id=${props.kategori_barang}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    const a = Promise.resolve(StorageApi.getRelasi('sm_master_data/jenis_barang_hilang' + param));
    a.then(value => {
      const lists = value.data.data;
      var daftar = [];
      lists.map(item => (
        daftar.push({ label: item.jenis_barang, value: item.rowid })
      ))
      setDetail(daftar)
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <SelectSearch
        className="w-full"
        value={props.value ?
          list.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}


/*
  ================
  = Custom Form  =
  ================
  Berisikan data, dimana data tersebut tidak diambil dari API yang terhubung ke backend melainkan data yang diparsing melalu front-end
*/


export function SelectCustom(props) {
  return (
    <>
      <SelectSearch
        className={`${props.className} w-full text-gray-900`}
        value={props.value ?
          props.children.filter(option =>
            option.value === props.value) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={props.children}
        name={props.name}
        required
        isDisabled={props.disabled ? true : false}
      />
    </>
  )
}


export function InputCustom(props) {
  return (
    <>
      <input
        type={props.type}
        name={props.name}
        accept={props.extension}
        className={`form-control block
        ${props.width ? props.width : "w-full"} px-3 py-1.5
        ${props.width ? 'mr-4' : ""}
        font-normal
        bg-white bg-clip-padding
        border border-solid border-gray-300
        h-9
        rounded transition
        ease-in-out m-0
        ${props.className}
        ${props.readOnly || props.required ? "bg-[#F5F5F5] focus:text-gray-700 focus:bg-white focus:border-red-500 focus:outline-red-500"
            : "bg-white focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"}
        `}
        placeholder={props.placeholder} value={props.value} onChange={props.onChange} onKeyUp={props.onKeyUp}
        readOnly={props.readOnly ? true : false}

      />
    </>
  )
}

export function StepsCustom(props) {
  return (
    <>
      <div className={props.className}>
        <div>
          <span>* menandakan form wajib di isi</span>
        </div>
        <div className='p-2'>
          <Link href={'/'}>
            <button className='mr-4'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-11 hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
              </svg>
              Prev
            </button>
          </Link>
          <Link href={{
            pathname: "/Pendaftaran/submitAccount",
            query: props.next
          }} >
            <button disabled={props.disabled}>
              <svg xmlns="http://www.w3.org/2000/svg" className={`${props.disabled ? "text-gray-700" : ""} hover:text-gray-700 h-10 w-10`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Next
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export function SearchCustom(props) {
  return (
    <>
      <div className={`${props.title ? 'container justify-between items-center flex' : ''}`}>
        {props.title ? (<>
          <div className="p-2">
            <h1 className={`${props.colorText} font-semibold`}>{props.title}</h1>
          </div>
        </>) : (<>
        </>)}
        <div className="p-2 flex items-center">
          <div className="relative w-full">
            <input
              type={props.type}
              name={props.name}
              className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-l-lg rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              onKeyUp={props.onKeyUp}
            />
            <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export function SearchCustomAlgors(props) {
  return (
    <>
      <div className={`${props.title ? 'container justify-between items-center flex' : ''}`}>
        {props.title ? (<>
          <div className="p-2">
            <h1 className="text-[#003AD2] font-semibold">{props.title}</h1>
          </div>
        </>) : (<>
        </>)}
        <div className="p-2 flex items-center">
          <input
            type={props.type}
            name={props.name}
            className={`appearance-none rounded-none block 
          w-full px-3 py-2  border-b-[3px] 
        border-gray-300 placeholder-gray-500 
        text-[#1F4173] font-semibold rounded-t-md focus:outline-none 
        focus:ring-indigo-500 focus:border-[#417CEA] 
        focus:z-10 sm:text-sm`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
          />
          <Image loader={e => { return 'https://backupsolo.matrik.co.id:3002/icons/search.svg' }} src="https://backupsolo.matrik.co.id:3002/icons/search.svg" alt="image" width={20} height={20} className="mr-2" />
        </div>
      </div>
    </>
  )
}

export function DivCustom(props) {
  const [show_required,setShowRequired] = useState()
  useEffect(() => {
    props.Isnumeric ? Infonumeric(props.Isnumeric) : ("")
  }, [props.Isnumeric])

  const Infonumeric = (numeric) => {
    new Intl.NumberFormat().format(numeric) == "NaN" ? (
      message.info(`${props.label} harus numeric, selain numeric tidak dapat diproses`,1)
    ) : ("")
  }

  return (
    <>
      <div className="form-group mb-4 items-center" onMouseEnter={e=>setShowRequired(true)} onMouseLeave={e=>setShowRequired(false)}>
        <div className="mb-2">
            <label htmlFor="" className={`mr-3  ${props.className ? props.className : 'text-grey-900'}`}>{props.label} {props.required ? <span className='text-red-500'>*</span>:<></>}{show_required ? 
          props.required ? <span className="text-[10px] font-semibold text-red-500 m-0 p-0"> (Wajib di isi)</span>:""
          :""}</label>
        </div>
        <div className='flex'>
          {props.form}
          {props.icon}
        </div>
        {props.info}
        {props.Isnumeric ? (<>
          {new Intl.NumberFormat().format(props.Isnumeric) == "NaN" ? (
            <p className="text-red-600 text-[11px] font-semibold ml-1 -mb-4">{props.label} Harus Numerik</p>
          ) : ("")}
        </>) : (<></>)}
      </div>
    </>
  )
}


export function Textarea(props) {
  return (
    <>
      <textarea className={`
          form-control block 
          ${props.width ? props.width : "w-full"}
          ${props.width ? 'mr-4' : ""}
          px-3 py-1.5 text-base font-normal
          rounded transition ease-in-out m-0
          text-gray-700
          bg-white bg-clip-padding
          border-gray-300 border border-solid 
          focus:text-gray-700 focus:bg-white 
          focus:border-blue-600 focus:outline-none`}

        id={props.id}
        rows={props.rows}
        cols={props.cols}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        readOnly={props.readOnly ? true : false}
      ></textarea>
    </>
  )
}
export function SelectPolresName(props) {
  const [list, setDetail] = useState([]);

  useEffect(e => {
    ListCategory(props.param);
  }, [props?.param])

  const ListCategory = async (polda_id) => {
    const a = Promise.resolve(StorageApi.getRelasi('polres?polda_id=' + polda_id));
    a.then(value => {
      const lists = value.data.data
      var daftar = [];
      lists.map(item =>
        props.wilayah ? daftar.push({ label: item.polres.replaceAll('POLRESTABES','').replaceAll('POLRESTA','').replaceAll('POLRES',''), value: item.polres }) : daftar.push({ label: item.polres, value: item.polres })
        )
      // props.clearSelect ? '' : daftar.unshift({ label: <><div className='items-center'><Image src="/icons/blacknonverify.svg" alt={'image'} width={15} height={15} /><span className='text-[9px]'>Clear Select</span></div></>, value: '' })
      setDetail(daftar)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }
  return (
    <>
      <SelectSearch
        className="w-full z-10 text-gray-900"
        value={props.value ?
          list.filter(option =>
            option.value == props.value ) : ""
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
        options={list}
        required
        isDisabled={(props.disabled) ? true : false}
      />
    </>
  )
}
export function Nodata(props){
  return(
    <div className='text-center xl:mt-36 md:mt-20 mb-32'>
      <Image src={'/images/not-avaliable.png'} alt={'info'} width={30} height={30} /> <br />
      <p className='text-white text-[14px] mt-3'>Tidak ada data</p>
    </div> 
  )
}

export function CapitalText(tujuan){
  if (tujuan) {
    return tujuan.replace(/\w\S*/g,
      function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() });
  } else {
    return;
  }
}

export function ConvertRibuan(param =''){
  return new Intl.NumberFormat('id').format(param)
}

// master data untuk perhitungan VCR

export function SelectKelasHambatan({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name}  disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : 'Sangat Tinggi' , value  :'Sangat Tinggi'},
            {label : 'Tinggi' , value  :'Tinggi'},
            {label : 'Sedang' , value  :'Sedang'},
            {label : 'Sangat Rendah' , value  :'Sangat Rendah'},
            {label : 'Rendah' , value  :'Rendah'},
          ]}
       </SelectCustom>
  )
}
export function SelectTipeAlinemen({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name}  disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : 'Datar' , value  :'Datar'},
            {label : 'Bukit' , value  :'Bukit'},
            {label : 'Gunung' , value  :'Gunung'},
            {label : 'Datar KJP A' , value  :'Datar KJP A'},
            {label : 'Datar KJP B' , value  :'Datar KJP B'},
            {label : 'Datar KJP C' , value  :'Datar KJP C'},
          ]}
       </SelectCustom>
  )
}
export function SelectLingkupJalan({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
           {[
            {label : 'Tol' , value  :'Tol'},
            {label : 'Perkotaan' , value  :'Perkotaan'},
            {label : 'Luar Kota' , value  :'Luar Kota'},
            {label : 'Simpang APIL' , value  :'Simpang APIL'},
            {label : 'Simpang Non APIL' , value  :'Simpang Non APIL'},
            {label : 'Jalinan' , value  :'Jalinan'},
          ]}
       </SelectCustom>
  )
}
export function SelectLajuAnalisa({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : 'Kanan' , value  :'Kanan'},
            {label : 'Kiri' , value  :'Kiri'},
            {label : 'Keduanya' , value  :'Keduanya'},
          ]}
       </SelectCustom>
  )
}
export function SelectKategoriLajurJalan({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : 'Jalan Terbagi' , value  :'Jalan Terbagi'},
            {label : 'Jalan Satu Arah' , value  :'Jalan Satu Arah'},
            {label : 'Jalan Tidak Terbagi' , value  :'Jalan Tidak Terbagi'},
          ]}
       </SelectCustom>
  )
}
export function SelectKodeLajur({Name,placeholder='',param='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  const [detail, setDetailData] = useState([])
  const [loader,setLoader] = useState(false)
  const tempKodeLajur = useRef()

  const getData = (parameter) => {
    setLoader(true)
    Promise.resolve(StorageApi.getRelasi('sm_master_data/vcr/vcr_type_lajur'))
      .then((value) => {
        const logdata = value.data.data
        const dispatchData = logdata.filter(item =>item.kategori_lajur_jalan == parameter && item ).map(item =>({label : item.kode_lajur,value : item.kode_lajur}))
        setLoader(false)
        setDetailData(dispatchData)
      }).catch((error) => {
        setLoader(false)
        setDetailData([])
      })
  }
  tempKodeLajur.current = getData

  useEffect(()=>{
    tempKodeLajur.current(param)
  },[param])

  return(
       <SelectCustom  Name={Name} loader={loader} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {detail}
       </SelectCustom>
  )
}
export function SelectLebarJalurEffektif({Name,param=[],showdata='',placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  const [detail, setDetailData] = useState([])
  const [loader,setLoader] = useState(false)
  const templebarjalur = useRef()

  const getData = (dataTampil) => {
    setLoader(true)
    Promise.resolve(StorageApi.getRelasi('sm_master_data/vcr/vcr_fclj' + `?kategori_lajur=${dataTampil}`))
      .then((value) => {
          const logdata = value.data.data
          const dispatchData = logdata.map(item =>  ({label : item.lebar_lajur_efektif,value : item.lebar_lajur_efektif}))
          setLoader(false)
          setDetailData(dispatchData)
      }).catch((error) => {
          setLoader(false)
          setDetailData([])
      })
  }
  
  templebarjalur.current = getData
  
  useEffect(()=>{
    templebarjalur.current(showdata)
  },[showdata])

  return(
       <SelectCustom  Name={Name} loader={loader} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {detail}
       </SelectCustom>
  )
}
export function SelectLebarLajurEffertif({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  const [detail, setDetailData] = useState([])
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    getData()
  },[])
  const getData = async() =>{
    setLoader(true)
    Promise.resolve(StorageApi.getRelasi('sm_master_data/layanan_darurat'))
      .then((value) => {
          const logdata = value.data.data
          const dispatchData = logdata.map(item => ({label : item.layanan_darurat,value : item.kode_layanan_darurat}))
          setLoader(false)
          setDetailData(dispatchData)
      }).catch((error) => {
          setLoader(false)
          setDetailData([])
      })
  }
  return(
       <SelectCustom  Name={Name} loader={loader} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {detail}
       </SelectCustom>
  )
}
export function SelectLebarBahu({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : '0' , value : '0'},
            {label : '<=0.5',value : '<=0.5'},
            {label : '1',value : '1'},
            {label : '1.5',value : '1.5'},
            {label : '>=2.0',value : '>=2.0'},
          ]}
       </SelectCustom>
  )
}
export function SelectLebarMedian({Name,param='',placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
 return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : '0' , value : '0', disabled : param === 'Jalan Terbagi' ? true : false},
            {label : '<=0.5',value : '<=0.5',disabled : param === 'Jalan Terbagi' ? false : true},
            {label : '1',value : '1',disabled : param === 'Jalan Terbagi' ? false : true},
            {label : '1.5',value : '1.5',disabled : param === 'Jalan Terbagi' ? false : true},
            {label : '>=2.0',value : '>=2.0',disabled : param === 'Jalan Terbagi' ? false : true},
          ]}
       </SelectCustom>
  )
}
export function SelectLebarTrotoar({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  return(
       <SelectCustom  Name={Name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {[
            {label : '0' , value : '0'},
            {label : '<=0.5',value : '<=0.5'},
            {label : '1',value : '1'},
            {label : '1.5',value : '1.5'},
            {label : '>=2.0',value : '>=2.0'},
          ]}
       </SelectCustom>
  )
}

export function SelectPersentasePA({Name,placeholder='',onChange,value,allowClear=false,showSearch=false,disabled=false,multiple=false}) {
  const [detail, setDetailData] = useState([])
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    getData()
  },[])
  const getData = async() =>{
    setLoader(true)
    Promise.resolve(StorageApi.getRelasi('sm_master_data/vcr/vcr_fcpa'))
      .then((value) => {
          const logdata = value.data.data
          const dispatchData = logdata.map(item => ({label : item.range_pa,value : item.range_pa}))
          setLoader(false)
          setDetailData(dispatchData)
      }).catch((error) => {
          setLoader(false)
          setDetailData([])
      })
  }
  return(
       <SelectCustom  Name={Name} loader={loader} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange} allowClear={allowClear} showSearch={showSearch} multiple={multiple}>
          {detail}
       </SelectCustom>
  )
}